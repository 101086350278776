import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityPdfInternalClarity', {
        templateUrl: 'app/pages/accessibility/pdf/internal/clarity/clarity.html',
        controller: AccessibilityPdfController,
        controllerAs: 'vm',
    });

    AccessibilityPdfController.$inject = [
        'PDFService',
        '$controller',
        'gettextCatalog',
        'ComponentDetailsView',
        '$filter',
        'ng2MonModalService',
        'ClarityService',
        '$q',
        'moment',
        'AccClarityRemediationService',
        'ng2MonUtilsService',
        'ng2ActiveFeatureService',
        'ng2MonEventsService',
    ];

    function AccessibilityPdfController (
        PDFService,
        $controller,
        gettextCatalog,
        ComponentDetailsView,
        $filter,
        ng2MonModalService,
        ClarityService,
        $q,
        moment,
        AccClarityRemediationService,
        ng2MonUtilsService,
        ng2ActiveFeatureService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.dropdownId = 'accessibilityPdfInternalClarityDropdown-' + ng2MonUtilsService.getRandomString();
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.setReview = setReview;
        vm.requestClarification = requestClarification;
        vm.openDetails = openDetails;
        vm.getClarityDate = getClarityDate;
        vm.clearSelectedPdfs = clearSelectedPdfs;
        vm.requestClarifications = requestClarifications;
        vm.createdIsOlderThanCompleted = createdIsOlderThanCompleted;
        vm.disableRemoveClarityRequest = disableRemoveClarityRequest;
        vm.getNoResultMessage = getNoResultMessage;
        vm.customOnTabChange = customOnTabChange;
        vm.afterMultipleRemediations = afterMultipleRemediations;
        vm.requestAllClarityChecks = requestAllClarityChecks;
        vm.markAllPdfsAsReviewed = markAllPdfsAsReviewed;
        vm.requestRemeditionQuoteForAllPdfs = requestRemeditionQuoteForAllPdfs;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.activeTab = 'pending';
            vm.tabHeader = gettextCatalog.getString('All Pending Internal PDF files');
            vm.tabSubHeader = gettextCatalog.getString(
                'A list of all internal PDF files that you need to check for accessibility issues',
            );
            vm.currentSort = { by: 'title', direction: 'desc' };
            vm.header = gettextCatalog.getString('Internal PDF Compliance');
            vm.claritySummary = {};
            vm.selectedPdfs = [];
            vm.pdfs = [];
            vm.dropdownOptions = [];
            vm.removingClarifications = false;
            vm.requestingClarifications = false;
            vm.search = '';
            vm.scanning = false;

            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'accessibility_pdfs',
                        category_ref: vm.activeTab === 'reviewed' ? 'reviewed' : 'pending',
                    },
                },
            ];

            setupTabs();
            getPage();
            getSummary();
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Pending'),
                    value: 'pending',
                },
                {
                    name: gettextCatalog.getString('Reviewed/Ok'),
                    value: 'reviewed',
                },
                {
                    name: gettextCatalog.getString('Queued'),
                    value: 'queued',
                },
                {
                    name: gettextCatalog.getString('Failed'),
                    value: 'failed',
                },
            ];
        }

        function customOnTabChange (tab) {
            vm.onTabChange(tab);
            switch (vm.activeTab) {
                case 'pending':
                    vm.tabHeader = gettextCatalog.getString('All Pending Internal PDF files');
                    vm.tabSubHeader = gettextCatalog.getString(
                        'A list of all internal PDF files that you need to check for accessibility issues',
                    );
                    break;
                case 'reviewed':
                    vm.tabHeader = gettextCatalog.getString('All Reviewed Internal PDF files');
                    vm.tabSubHeader = gettextCatalog.getString(
                        'A list of all internal PDF files already reviewed for accessibility issues',
                    );
                    break;
                case 'queued':
                    vm.tabHeader = gettextCatalog.getString('All Queued Internal PDF files');
                    vm.tabSubHeader = gettextCatalog.getString(
                        'A list of all internal PDF files that have been marked as queued for accessibility issues',
                    );
                    break;
                case 'failed':
                    vm.tabHeader = gettextCatalog.getString('All Failed Internal PDF files');
                    vm.tabSubHeader = gettextCatalog.getString(
                        'A list of all internal PDF files that have been marked as failed for accessibility issues',
                    );
                    break;
            }
            updateDropdown();
        }

        function getPage () {
            var params = {
                type: 'pdf',
                page_size: vm.pageSize,
                page: vm.page,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                clarity: vm.activeTab,
                search: vm.search || null,
            };

            if (vm.activeTab === 'pending') {
                params.reviewed = false;
            } else if (vm.activeTab === 'reviewed') {
                delete params.clarity;
                params.reviewed = true;
            }

            vm.progress = PDFService.get(params).then(function (pdfs) {
                vm.pdfs = pdfs;
                updateTableDropdowns();
                updateDropdown();
            }, angular.noop);
        }

        function updateDropdown () {
            const dropdown = [
                {
                    label: gettextCatalog.getString('Mark all as reviewed'),
                    leftIcon: 'faUndo',
                    action: markAllPdfsAsReviewed,
                    eventParams: { action: 'mark-all-as-reviewed' },
                    shouldShow: vm.activeTab !== 'reviewed',
                },
                {
                    label: gettextCatalog.getString('Request PDF accessibility check for all'),
                    leftIcon: 'faUniversalAccess',
                    action: requestAllClarityChecks,
                    eventParams: { action: 'request-all-PDF-accessibility-checks' },
                    shouldShow: ng2ActiveFeatureService.isFeatureActive('customer_admin') && (vm.activeTab === 'pending' || vm.activeTab === 'failed') && vm.claritySummary.tokens > vm.pdfs.length && vm.pdfs.length !== 0 && !vm.scanning,
                },
                {
                    label: gettextCatalog.getString('Request Remedition quote for all'),
                    leftIcon: 'faFilePdf',
                    action: requestRemeditionQuoteForAllPdfs,
                    eventParams: { action: 'request-all-remedition-quotes' },
                    shouldShow: ng2ActiveFeatureService.isFeatureActive('clarity_pdf_remediation'),
                },
            ];

            vm.dropdownOptions = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                return {
                    label: item.label,
                    leftIcon: item.leftIcon,
                    action: ()=>{
                        ng2MonEventsService.run('actionTrigger', item.eventParams);
                        item.action();
                    },
                };
            });
        }

        function updateTableDropdowns () {
            for (const pdf of vm.pdfs) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Marked as reviewed'),
                        leftIcon: 'faCheck',
                        action: ()=>setReview(pdf),
                        eventParams: { action: 'accessibility-pdf-review' },
                        shouldShow: !pdf.reviewed,
                    },
                    {
                        label: gettextCatalog.getString('Undo'),
                        leftIcon: 'faUndo',
                        action: ()=>setReview(pdf),
                        eventParams: { action: 'accessibility-pdf-review' },
                        shouldShow: pdf.reviewed,
                    },
                    {
                        label: gettextCatalog.getString('Start PDF accessibility scan'),
                        leftIcon: 'faFilePdf',
                        action: ()=>requestClarification(pdf),
                        eventParams: { action: 'accessibility-pdf-scan' },
                        shouldShow: pdf.clarity_queued_at === null,
                    },
                ];

                pdf.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        action: ()=>{
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                            item.action();
                        },
                    };
                });
            }
        }

        function getSummary () {
            ClarityService.getSummary().then(function (summary) {
                vm.claritySummary = summary;
                updateDropdown();
            }, angular.noop);
        }

        function setReview (pdf) {
            var msg;
            if (pdf.reviewed) {
                msg = gettextCatalog.getString('Are you sure you want to undo review of this document?');
            } else {
                msg = gettextCatalog.getString('Are you sure you want to set this document as reviewed?');
            }

            ng2MonModalService.confirm(msg).then(function () {
                var params = {
                    reviewed: !pdf.reviewed,
                    id: pdf.id,
                };

                PDFService.update(pdf, params).then(function () {
                    getPage();
                }, angular.noop);
            }, angular.noop);
        }

        function requestClarification (pdf) {
            var translateParams = {
                name: '<strong>' + pdf.title || pdf.url + '</strong>',
            };
            var translation = gettextCatalog.getString(
                'Are you sure you want to the request ADA clarification for {{name}}?',
                translateParams,
            );

            if (!createdIsOlderThanCompleted(pdf)) {
                translation = gettextCatalog.getString(
                    '{{name}} have already been scanned for compliance. Are you sure you want to scan it again?',
                    translateParams,
                );
            }

            ng2MonModalService.confirm(translation).then(function () {
                requestClarityCheck(pdf)
                    .then(angular.noop, tokensExpired)
                    .finally(function () {
                        getPage();
                        getSummary();
                    });
            }, angular.noop);
        }

        function openDetails (pdf) {
            var params = {
                target: 'accessibility',
            };
            ComponentDetailsView.goToDocumentDetails(pdf, params, getPage);
        }

        function getClarityDate (pdf) {
            if (typeof pdf.clarity_queued_at === 'string') {
                return $filter('userDate')(pdf.clarity_queued_at);
            } else if (typeof pdf.clarity_checked_at === 'string') {
                return $filter('userDate')(pdf.clarity_checked_at);
            }
            return '';
        }

        function requestClarifications () {
            var containsScannedDocuments = vm.selectedPdfs.some(function (pdf) {
                return !createdIsOlderThanCompleted(pdf);
            });
            if (containsScannedDocuments) {
                ng2MonModalService
                    .confirm(
                        gettextCatalog.getString(
                            'One or more documents have already been scanned for compliance. Are you sure you want to scan the selected documents again?',
                        ),
                    )
                    .then(requestClarityChecks, angular.noop);
            } else {
                requestClarityChecks();
            }
        }

        function clearSelectedPdfs () {
            vm.selectedPdfs = [];
        }

        function createdIsOlderThanCompleted (pdf) {
            return pdf.clarity_checked_at === null || moment(pdf.created_at).isAfter(pdf.clarity_checked_at);
        }

        function disableRemoveClarityRequest () {
            if (vm.activeTab === 'queued') {
                return vm.selectedPdfs.length === 0 || vm.removingClarifications;
            }
            return true;
        }

        function getNoResultMessage () {
            switch (vm.activeTab) {
                case 'pending':
                    return gettextCatalog.getString('No PDF\'s pending');
                case 'reviewed':
                    return gettextCatalog.getString('No PDF\'s reviewed');
                case 'queued':
                    return gettextCatalog.getString('No PDF\'s queued');
                case 'failed':
                    return gettextCatalog.getString('No PDF\'s failed');
            }
        }

        function afterMultipleRemediations () {
            clearSelectedPdfs();
            getPage();
        }

        function requestAllClarityChecks () {
            vm.scanning = true;
            updateDropdown();
            var translation = gettextCatalog.getString(
                'Are you sure you want to Request All PDF Accessibility checks?',
            );
            ng2MonModalService.confirm(translation).then(function () {
                PDFService.createAllClarity()
                    .then(angular.noop, tokensExpired)
                    .finally(function () {
                        vm.scanning = false;
                        updateDropdown();
                        getPage();
                        getSummary();
                    });
            }, angular.noop);
        }

        function markAllPdfsAsReviewed () {
            var translation = gettextCatalog.getString('Are you sure you want to set these documents as reviewed?');
            ng2MonModalService.confirm(translation).then(function () {
                PDFService.markAllPdfsAsReviewed()
                    .then(function () {
                        getPage();
                    });
            }, angular.noop);
        }

        function requestRemeditionQuoteForAllPdfs () {
            AccClarityRemediationService.createRemediationDialog(() => {
                PDFService.requestRemeditionQuoteForAllPdfs()
                    .then(function () {
                        getPage();
                    });
            });
        }

        // PROTECTED

        function requestClarityChecks () {
            var chain = $q.when();
            var pdfsSucceeded = 0;
            var error = false;
            var filteredPdfs = vm.selectedPdfs.filter(function (pdf) {
                return typeof pdf.clarity_queued_at !== 'string';
            });

            filteredPdfs.forEach(function (pdf) {
                chain = chain
                    .then(function () {
                        return requestClarityCheck(pdf);
                    })
                    .then(
                        function () {
                            pdfsSucceeded++;
                        },
                        function (res) {
                            if (!error) {
                                error = true;
                                tokensExpired(res);
                            }
                            return $q.reject();
                        },
                    );
            });

            vm.requestingClarifications = true;
            chain.then(angular.noop, angular.noop).finally(function () {
                vm.requestingClarifications = false;
                getSummary();
                var params = {
                    succeededPdfs: pdfsSucceeded,
                    pdfs: vm.selectedPdfs.length,
                };
                ng2MonModalService
                    .alert(
                        gettextCatalog.getString(
                            '{{succeededPdfs}} out of {{pdfs}} PDF accessibility request succeeded',
                            params,
                        ),
                    )
                    .finally(function () {
                        clearSelectedPdfs();
                        getPage();
                        getSummary();
                    });
            });
        }

        function tokensExpired (res) {
            if (res.status === 402) {
                return ng2MonModalService
                    .alert(
                        gettextCatalog.getString(
                            'No more PDF accessibility scans are available for this account. Please contact a Acquia Optimize sales department to add more.',
                        ),
                    )
                    .then($q.reject, $q.reject);
            } else {
                return ng2MonModalService.alert(res.data.message).then($q.reject, $q.reject);
            }
        }

        function requestClarityCheck (pdf) {
            return PDFService.createClarity(pdf, contextTokens.NO_GLOBAL);
        }
    }
})();
