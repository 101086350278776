(function () {
    'use strict';

    angular.module('blocks.base').controller('BasePagesController', BasePagesController);

    BasePagesController.$inject = [
        'vm', 'ng2LabelRepo', 'pageService', 'activeFeatures', 'SpellingRepo', '$filter', 'ng2SessionService'];
    /* @ngInject */

    /**
     * Contains shared function between components with Acquia Optimize pages
     *
     * @memberof blocks.base
     * @ngdoc controller
     * @author Jens Emil Sørensen
     * @name BasePagesController
     * @param       {}          vm              The component to extend
     * @param       {ng2LabelRepo} ng2LabelRepo       The ng2LabelRepo
     * @param       {pageService}  pageService        The pageService
     * @param       {}          activeFeatures  List of active features
     * @constructor
     */
    function BasePagesController (vm, ng2LabelRepo, pageService, activeFeatures, SpellingRepo, $filter, ng2SessionService) {
        vm.getLabels = getLabels;
        vm.getLanguages = getLanguages;
        vm.updateLabels = updateLabels;
        vm.updateFilters = updateFilters;
        vm.updateParamsWithFilters = updateParamsWithFilters;

        activate();

        function activate () {
            vm.labels = [];
            vm.showLabels = activeFeatures.labels;

            vm.isFiltersOpen = false;
            vm.selectedLabels = [];
            vm.selectedLanguages = [];
            vm.showFilters = activeFeatures.table_filters;

            vm.filters = { labels: [], languages: [] };
            vm.availableFilters = {
                labels: [],
                languages: [],
            };

            vm.isAdmin = ng2SessionService.hasRole('customer_admin');

            vm.getLabels();
            vm.getLanguages();
        }

        function getLabels () {
            var params = {
                page_size: 1000,
            };

            ng2LabelRepo.getLabels(params).then(function (data) {
                vm.labels = data || [];
                vm.availableFilters.labels = data || [];
            }, angular.noop);
        }

        function updateLabels (page) {
            var params = {};
            params.labels = page.labels.map(function (label) {
                return label.id;
            });
            pageService.update(page, params).then(angular.noop, angular.noop);
        }

        function getLanguages () {
            SpellingRepo.getAllLanguages().then(function (data) {
                vm.availableFilters.languages = data.map(function (language) {
                    return { name: $filter('translateLanguage')(language.lang), lang: language.lang };
                });
            }, angular.noop);
        }

        function updateFilters (changes) {
            if (changes.changes) {
                changes = changes.changes;
            }

            if (changes.labels) {
                vm.selectedLabels = changes.labels.map(function (label) {
                    return label.id;
                });
            } else {
                vm.selectedLabels = [];
            }

            if (changes.languages) {
                vm.selectedLanguages = changes.languages.map(function (language) {
                    return language.lang;
                });
            } else {
                vm.selectedLanguages = [];
            }
        }

        function updateParamsWithFilters (params) {
            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }

            if (vm.selectedLanguages && vm.selectedLanguages.length > 0) {
                params['languages[]'] = vm.selectedLanguages;
            }

            return params;
        }
    }
})();
